import axios from 'axios';


class StudentServices {
    createStudent = async param => {
        await axios.post(`${process.env.REACT_APP_API_HOST}/students`, param)
    }
    
    deleteStudent = async id => {

        
        await axios.delete(`${process.env.REACT_APP_API_HOST}/students/${id}`)
    }
}

export default new StudentServices();
