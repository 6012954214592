import React, { Component } from 'react';
import styled from "styled-components";
import BarChartIcon from "@material-ui/icons/BarChart";
import MicIcon from "@material-ui/icons/Mic";
import MainLayout from "../../layouts/mainLayout";
import IconButton from "../../atoms/IconButton";
import CustomColors from "../../../consts/customColors";
import PageTitle from "../../atoms/PageTitle";



class TopPage extends Component {
    render(){
        const { history } = this.props;
        
        return (
            <MainLayout>
                <PageTitle title='メニューを選択してください' />
                <StyledButtonArea>
                    <IconButton
                        label='録音'
                        bgColor={CustomColors.backgroundYellow}
                        color={CustomColors.primary}
                        icon={<MicIcon style={{ fontSize: '130px', marginTop: '20px' }}/>}
                        handleClick={() => history.push('/voice')}
                    />
                    
                    <IconButton
                        label='状況'
                        bgColor={CustomColors.primary}
                        color={CustomColors.white}
                        icon={<BarChartIcon style={{ fontSize: '130px', marginTop: '20px' }}/>}
                        handleClick={() => history.push('/list')}
                    />
                </StyledButtonArea>
            </MainLayout>
        );
    }
}

const StyledButtonArea = styled.div`
    margin: 40px auto;
    display: flex;
    justify-content: space-around;
`

export default TopPage;