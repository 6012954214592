const CustomSize = {
    1: '年少',
    2: '年中',
    3: '年長',
    4: '小１',
    5: '小２',
    6: '小３',
    7: '小４',
    8: '小５',
    9: '小６'
}

export default CustomSize;
