import React, {useState} from 'react';
import Index from "../../organisms/MicController";
import MainLayout from "../../layouts/mainLayout";
import PageTitle from "../../atoms/PageTitle";
import TalkList from "../../organisms/TolkList";
import StudentForm from "../../organisms/StudentForm";


const VoicePage = ()  => {
    // stateを設定
    const [modalOpen, setModalOpen] = useState(true);
    const [currentStudent, setStudent] = useState(null)
    
    return (
        <MainLayout>
            <PageTitle title='音声を録音しましょう' />
            <Index currentStudent={currentStudent} />
            { currentStudent && <TalkList currentStudent={currentStudent} /> }
            <StudentForm
                setOpen={setModalOpen}
                isOpen={modalOpen}
                setStudent={setStudent}
            />
        </MainLayout>
    );
}

export default VoicePage;
