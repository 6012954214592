import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CustomColors from "./consts/customColors";
import TopPage from './components/pages/TopPage';
import VoicePage from './components/pages/VoicePage';
import ListPage from './components/pages/ListPage';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: CustomColors.primary
        }
    }
});

class App extends Component {
    render() {
    return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route exact path='/' component={TopPage}/>
                <Route exact path='/list' component={ListPage}/>
                <Route exact path='/voice' component={VoicePage}/>
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
    );
  }
}

export default App;
