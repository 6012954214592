import React, {useCallback, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton
} from "@material-ui/core";
import styled from 'styled-components';
import _ from "lodash";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import CustomColors from "../../../consts/customColors";

const WordList = ({ data })  => {
    const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortBy, setSortBy] = React.useState(null);
    const [orderBy, setOrderBy] = React.useState('asc');
    const [list, setList] = React.useState([]);
    const rowsPerPage = 10;
    
    // 配列をソートする
    const sortList = useCallback(tmpList => {
        const sortedList = _.sortBy(tmpList, [function(l) { return l[sortBy]; }]);
        const orderdList = orderBy === 'desc' ? sortedList : _.reverse(sortedList);
        return orderdList
    }, [sortBy, orderBy]);
    
    // リストを更新したいとき
    // - dataを変更した時
    // - 並び順を変更した時
    useEffect(() => {
        // HACK マウント時には動かしたくなくて、その判断を
        // sortByの有無で判断しているのが気持ち悪い
        if(sortBy) {
            const sortedList = sortList(data);
            setList(sortedList);
        } else {
            setList(data);
        }
    }, [sortBy, orderBy, data, sortList]);
    
    
    // ページを変更
    const handleChangePage = (e, type) => {
        if(type === 'next') {
            setPage(page+1);
        } else {
            setPage(page-1);
        }
    };
    
    // 行数を変える
    // const handleChangeRowsPerPage = e => {
    //     setRowsPerPage(+e.target.value);
    //     setPage(0);
    // };
    
    // ソートボタンを押した時
    const handleChangeSort = headerName => {
        if(sortBy === headerName) {
            // 逆にしただけ
            setOrderBy(toggleOrderBy());
        } else {
            // ソートするkeyを変更
            setSortBy(headerName);
            setOrderBy('asc');
        }
        // ページは最初に戻す
        setPage(0);
    }
    
    // オーダーを逆にする
    const toggleOrderBy = () => {
        return  orderBy === 'asc' ? 'desc' : 'asc';
    }
    

    
    return (
            <StyledTableArea>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortBy === 'id'}
                                    direction={ orderBy }
                                    onClick={e => handleChangeSort('id')}
                                >
                                ユーザーID
                                </TableSortLabel>
                            </TableCell>
                            
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortBy === 'word'}
                                    direction={ orderBy }
                                    onClick={e => handleChangeSort('word')}
                                >
                                    単語
                                </TableSortLabel>
                            </TableCell>
    
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortBy === 'type'}
                                    direction={ orderBy }
                                    onClick={e => handleChangeSort('type')}
                                >
                                    品詞
                                </TableSortLabel>
                            </TableCell>
                            
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortBy === 'count'}
                                    direction={ orderBy }
                                    onClick={e => handleChangeSort('count')}
                                >
                                    回数
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                        {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((l) => (
                            <TableRow key={`${l.id}_${l.word}`}>
                                <TableCell
                                    align="center"
                                >
                                    {l.id}
                                </TableCell>
                                
                                <TableCell align="center">
                                    {l.word}
                                </TableCell>
                                
                                <TableCell align="center">
                                    {l.type}
                                </TableCell>
                                
                                <TableCell align="right">
                                    {l.count}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                
                <StyledPagenateArea>
                    <IconButton
                        onClick={e => handleChangePage(e, 'back')}
                        disabled={page===0}
                    >
                        <ArrowBackOutlinedIcon />
                    </IconButton>
                    
                    <span>{page+1} / {Math.ceil(list.length/rowsPerPage)}</span>
                    
                    <IconButton
                        onClick={e => handleChangePage(e, 'next')}
                        disabled={page===Math.ceil(list.length/rowsPerPage)-1}
                    >
                        <ArrowForwardOutlinedIcon />
                    </IconButton>
                </StyledPagenateArea>
            </StyledTableArea>
    )
};

const StyledTableArea = styled.div`
    background-color: ${CustomColors.white};
    padding: 4px 20px;
    border-radius: 20px;
`

const StyledPagenateArea = styled.div`
    text-align: center;
    color: ${CustomColors.primary};
    font-weight: bold;
    margin-top: 20px;
`

export default WordList;
