import React, { useState, useEffect }  from 'react';
import "firebase/storage";
import FiredataService from "../../../services/firedataService"
import WordList from "../../organisms/WordList";
import MainLayout from "../../layouts/mainLayout";
import PageTitle from "../../atoms/PageTitle";

const ListPage = ()  => {
    
    // stateを設定
    const [data, setData] = useState([]);
    
    // マウントした時
    useEffect(() => {
        getFireData();
    }, []);
    
    // dataをupdateした時
    useEffect(() => {
        console.log('ListPage: mount/update');
    }, [data]);
    
    // Firebaseからのデータ取得
    const getFireData = () => {
        FiredataService.getWordData(setData)
    }
    
    return (
        <MainLayout>
            <PageTitle title='分析結果' />
            <WordList data={data} />
        </MainLayout>
    );
}

export default ListPage;
