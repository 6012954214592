import styled from "styled-components";
import CustomColors from "../../../consts/customColors";

const PageTitle = ({ title }) => {
    return (
        <StyledTitle>
            { title }
        </StyledTitle>
    );
};

const StyledTitle = styled.h2(props => `
    color: ${CustomColors.primary};
    text-align: center;
    font-weight: bold;
    margin: 0 0 20px 0;
    font-size: 20px;
`)

export default PageTitle;