import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import CustomColors from "../../../consts/customColors";
import CustomSize from "../../../consts/customSize";
import FiredataService from "../../../services/firedataService";

const TalkList = ({ currentStudent })  => {
    const [data, setData] = useState([]);
    
    useEffect(() => {
        FiredataService.getSentenceData(setData, currentStudent.id);
    }, [currentStudent.id]);
    
    return (
        <>
            <p>{ currentStudent.nickname }</p>
            <StyledTextArea>
                {
                    data.map(d => (
                        <StyledSentenceArea key={d.date}>
                            {d.text}
                            <span>{d.date}</span>
                        </StyledSentenceArea>
                    ))
                }
            </StyledTextArea>
        </>
    )
};

const StyledTextArea = styled.ol`
    background-color: ${CustomColors.white};
    padding: 20px;
    border-radius: 20px;
`

const StyledSentenceArea = styled.li`
    margin: 0 0 20px 0;
    list-style: none;
    span {
        display: block;
        font-size: ${CustomSize.fontSmall};
        color: ${CustomColors.primary};
        text-align: right;
    }
`

export default TalkList;
