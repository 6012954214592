import React, {useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    Select,
    MenuItem,
    TextField,
    Tabs,
    Tab,
    Box,
    InputLabel,
} from '@material-ui/core/';
import FiredataService from "../../../services/firedataService";
import StudentService from "../../../services/studentServices";
import gradeEnum from "../../../consts/gradeEnum";
import styled from "styled-components";


const TabPanel = ({children, value, index}) => {
        
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
};

const StudentForm = ({setOpen, isOpen, setStudent}) => {
    const [selectedId, setSelectedId] = React.useState('');
    const [selectedGrade, setSelectedGrade] = React.useState('1');
    const [studentList, setStudentList] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [inputText, setInputText] = React.useState("");
    
    // マウントした時
    useEffect(() => {
        FiredataService.getStudentList(setStudentList);
    }, []);

    const handleClickSelect = () => {
        const currentStudent = studentList.find((s) => s.id === selectedId);
        setStudent(currentStudent);
        setOpen(false);
    }
    
    const handleClickCreateSubmit = async () => {
        const param = {
            nickname: inputText,
            grade: gradeEnum[selectedGrade],
        }
        await StudentService.createStudent(param);
        setInputText("");
        setSelectedGrade(0);
    }
    
    const handleClickDelete = async () => {
        await StudentService.deleteStudent(selectedId);
        setSelectedId('');
    }
    
    return (
        <StyledDialog>
            <Dialog
                open={isOpen}
                disableBackdropClick={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogContent>
                    <Tabs
                        value={tabIndex}
                        onChange={(e, index) => setTabIndex(index)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab
                            label="生徒選択"
                            id="scrollable-auto-tab-0"
                            aria-controls="scrollable-auto-tabpanel-0"
                        />
                        <Tab
                            label="新規作成"
                            id="scrollable-auto-tab-1"
                            aria-controls="scrollable-auto-tabpanel-1"
                        />
                        <Tab
                            label="削除"
                            id="scrollable-auto-tab-2"
                            aria-controls="scrollable-auto-tabpanel-2"
                        />
                    </Tabs>
                    
                    <TabPanel
                        value={tabIndex}
                        index={0}
                    >
                        <StyledInput
                            width={200}
                        >
                            <InputLabel id="demo-simple-select-helper-label">生徒</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth={true}
                                value={selectedId}
                                onChange={e => setSelectedId(e.target.value)}
                            >
                                {
                                    studentList.map(s => (
                                        <MenuItem
                                            value={s.id}
                                            key={s.id}
                                        >
                                            {s.nickname} ({s.grade})
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </StyledInput>
                        
                        <Button
                            color="primary"
                            disabled={!selectedId}
                            onClick={e => handleClickSelect()}
                            >
                            決定
                        </Button>
                    </TabPanel>
                    
                    <TabPanel
                        value={tabIndex}
                        index={1}
                    >
                        <StyledInput
                            width={200}
                        >
                            <InputLabel id="demo-simple-select-helper-label">ニックネーム</InputLabel>
                            <TextField
                                id="standard-basic"
                                fullWidth={true}
                                value={inputText}
                                onChange={e => setInputText(e.target.value)}
                            />
                        </StyledInput>
        
                        <StyledInput>
                            <InputLabel id="demo-simple-select-helper-label">学年</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth={true}
                                value={selectedGrade}
                                onChange={e => setSelectedGrade(e.target.value)}
                            >
                                {
                                    Object.keys(gradeEnum).map(key => (
                                        <MenuItem
                                            value={key}
                                            key={key}
                                        >
                                            {gradeEnum[key]}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </StyledInput>
        
                        <Button
                            color="primary"
                            disabled={inputText <= 0}
                            onClick={e => handleClickCreateSubmit()}
                        >
                            登録
                        </Button>
                    </TabPanel>
        
                    <TabPanel
                        value={tabIndex}
                        index={2}
                    >
                        <StyledInput
                            width={200}
                        >
                            <InputLabel id="demo-simple-select-helper-label">生徒</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth={true}
                                value={selectedId}
                                onChange={e => setSelectedId(e.target.value)}
                            >
                                {
                                    studentList.map(s => (
                                        <MenuItem
                                            value={s.id}
                                            key={s.id}
                                        >
                                            {s.nickname} ({s.grade})
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </StyledInput>
    
                        <Button
                            color="primary"
                            disabled={!selectedId}
                            onClick={e => handleClickDelete()}
                        >
                            削除
                        </Button>
                    </TabPanel>
                </DialogContent>
            </Dialog>
        </StyledDialog>
    );
}

const StyledInput = styled.div(props => `
    width: ${props.width}px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 20px;
`);

const StyledDialog = styled.div`
    width: 500px;
`

export default StudentForm;
