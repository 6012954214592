import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { io } from "socket.io-client";
import MicIcon from "@material-ui/icons/Mic";
import CustomColors from "../../../consts/customColors";
import IconButton from "../../atoms/IconButton";
import SimpleModal from "../../atoms/SimpleModal";

const API_HOST = process.env.REACT_APP_API_HOST;

const Index = ({currentStudent}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const audioContext = useRef();
    const socket = useRef();
    const node = useRef();
    const source = useRef();
    const chk = useRef();
    const chkSize = 20;
    const sizeCounter = useRef();

    useEffect(() => {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        socket.current = io(`${API_HOST}/speech`, {transports: ['websocket']});
        socket.current.on("timelimit", data => {
            timeOut();
        });
        return () => {
            if ('disconnect' in socket.current) {
                socket.current.disconnect();
            }
        };
    }, []);
    
    const successCallback = (mediaStream) => {
        source.current = audioContext.current.createMediaStreamSource(mediaStream);
        node.current = audioContext.current.createScriptProcessor(4096, 1, 1);
        node.current.onaudioprocess = function(data) {
            const voice = data.inputBuffer.getChannelData(0);
            const int16buffer = Int16Array.from(voice.map(x => x > 0 ? x*0x7FFF : x*0x8000))
            // socket.current.emit('speech', int16buffer);
            chk.current.push(int16buffer)
            sizeCounter.current += 1;
            // console.log(int16buffer);
            if (sizeCounter.current === chkSize) {
                // emit.
                console.log("emit")
                socket.current.emit('speech', chk.current);
                sizeCounter.current = 0;
                chk.current = [];
            }
        }
        source.current.connect(node.current);
        node.current.connect(audioContext.current.destination);
    }
    
    const manageRecord = () => {
        if(isRecording) {
            node.current.disconnect();
            source.current.disconnect();
            socket.current.emit('speech', chk.current);
            socket.current.emit('speech_stop');
            chk.current = [];
            sizeCounter.current = 0;
        } else {
            chk.current = [];
            sizeCounter.current = 0;
            socket.current.emit('speech_start',
                currentStudent.id,
                audioContext.current.sampleRate);
            // マイクへのアクセス権を取得
            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then(successCallback)
        }
        setIsRecording(!isRecording);
    }
    
    const timeOut = () => {
        node.current.disconnect();
        source.current.disconnect();
        socket.current.emit('speech_stop');
        chk.current = [];
        sizeCounter.current = 0;
        setIsRecording(false);
        setModalOpen(true);
    }

    return (
        <div>
            <StyledButtonArea>
                <IconButton
                    variant="contained"
                    color={isRecording ? CustomColors.white : CustomColors.primary}
                    bgColor={isRecording ? CustomColors.primary : CustomColors.backgroundYellow}
                    icon={<MicIcon style={{ fontSize: '130px', marginTop: '20px' }}/>}
                    handleClick={() => manageRecord()}
                    label={isRecording ? "録音を終了" : "録音を開始"}
                />
            </StyledButtonArea>
            <SimpleModal
                setOpen={setModalOpen}
                isOpen={modalOpen}
                title="タイムアウトしました"
                text="長時間接続されていたため、接続を停止しました。再開する場合は、再度録音開始ボタンを押してください。"
            />
        </div>
    );
}

const StyledButtonArea = styled.div`
    margin: 40px auto;
    display: flex;
    justify-content: space-around;
`

export default Index;
