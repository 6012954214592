import styled from "styled-components";
import CustomColors from "../../consts/customColors";
import CustomSize from "../../consts/customSize";

const MainLayout = props => {
    return (
        <div>
            <StyledHeader>
                DEMO APP
            </StyledHeader>
            
            <StyledMain>
                { props.children }
            </StyledMain>
            
            <StyledFooter>
            
            </StyledFooter>
        </div>
    )
}

const StyledHeader = styled.header`
    border-bottom: solid 2px ${CustomColors.primary};
    color: ${CustomColors.primary};
    text-align: center;
    font-size: ${CustomSize.fontLarge};
    font-weight: bold;
    padding: 10px 0;
`

const StyledMain = styled.main`
    background-color: ${CustomColors.background};
    max-width: 980px;
    width: 80%;
    margin: 40px auto;
    padding: 40px;
    border-radius: 20px;
`

const StyledFooter = styled.footer`
    border-top: solid 2px ${CustomColors.primary};
`

export default MainLayout;