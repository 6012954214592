import Firebase from "../utils/firebase";
import moment from 'moment-timezone';

class FiredataService {
    db = Firebase.database();
    
    getWordData = callBack => {
        const ref = this.db.ref("speeches/");
        ref.on("value", snapshot => {
            const users = snapshot.val();
            // ページ用にデータを整形
            let val = [] ?? {};
            Object.keys(users).forEach(user => {
                const words = users[user].words;
                for (let w in words) {
                    val.push({
                        id: user,
                        word: w,
                        count: words[w].count,
                        type: words[w].part_of_speech,
                    })
                }
            });
            callBack(val);
        });
    }
    
    getSentenceData = (callBack, id) => {
        const ref = this.db.ref(`speeches/${id}/sentences`).orderByKey().limitToLast(5);
        ref.on("value", snapshot => {
            const val = snapshot.val() ?? {};
            // ページ用にデータを整形
            let sentences = []
            Object.keys(val).forEach(key => {
                sentences.push({
                    date: moment.unix(key).format('YYYY-MM-DD HH:mm:ss'),
                    text: val[key].text
                })
            });
            callBack(sentences);
        });
    }
    
    getStudentList = callBack => {
        const ref = this.db.ref('students');
        ref.on("value", snapshot => {
            const val = snapshot.val() ?? {};
            // ページ用にデータを整形
            let studentList = [];
            Object.keys(val).forEach(key => {
                if(!val[key].deleted_at) {
                    studentList.push(
                        {
                            id: key,
                            nickname: val[key].nickname,
                            grade: val[key].grade,
                            deleted_at: val[key].deleted_at
                        }
                    );
                }
            });
            callBack(studentList);
        });
    }
}

export default new FiredataService();
