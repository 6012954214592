import styled from "styled-components";

const IconButton = ({ bgColor, color, icon ,label, handleClick }) => {
    return (
        <StyledButton
            bgColor={bgColor}
            color={color}
            icon={icon}
            onClick={e => {handleClick()}}
        >
            { icon }
            <StyledLabel>{ label }</StyledLabel>
        </StyledButton>
    )
}

const StyledButton = styled.div(props => `
    background-color: ${props.bgColor};
    color: ${props.color};
    text-align: center;
    font-weight: bold;
    padding: 14px;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    display: inline-block;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
`)

const StyledLabel = styled.div`
    font-size: 28px;
`

export default IconButton;